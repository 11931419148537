require('./src/assets/sass/template.scss')
window.$ = require('./src/assets/js/custom/jquery')
window.jQuery = window.$;
require('./src/assets/js/custom/jqBootstrapValidation')
require('./src/assets/js/custom/owl.carousel')
require('./src/assets/js/custom/prism')
require('./src/assets/js/custom/jquery.countTo')
require('./src/assets/js/custom/jquery.sticky-kit')
require('./src/assets/js/custom/jquery.superslides')
require('./src/assets/js/custom/appear')
require('./src/assets/js/custom/jquery.magnific-popup')
require('./src/assets/js/custom/jquery.singlePageNav')
require('./src/assets/js/custom/submenu-fix')
require('./src/assets/js/custom/prism')

exports.onInitialClientRender  = () => {
  require("./src/i18n");
  require('./src/assets/js/custom/custom')
}
